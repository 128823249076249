<template>
	<el-dialog title="" :visible.sync="createdVisible" width="578px" center :close-on-click-modal="false">
		<div slot="title" class="postion">
			<i v-if="!isShowDetail" class="iconfont icon-bianji1"></i>
			<span class="download">{{ isShowDetail ? '查看' : !isShowDetail && ruleForm.id ? '编辑' : '新增' }}科目</span>
		</div>
		<RsForm
			ref="rsForm"
			labelwidth="100px"
			:columns="columns"
			:inline="false"
			:saveBtn="true"
			:resetBtn="true"
			:searchBtn="false"
			label-position="top"
			:rules="isShowDetail ? {} : rules"
			:saveLoading="saveLoading"
			@query="onSave"
			@reset="resetForm"
			:isShowDetail="isShowDetail"
		/>
		<span slot="footer" class="dialog-footer" v-if="isShowDetail">
			<el-button type="primary" @click="resetForm()">确 定</el-button>
		</span>
	</el-dialog>
</template>

<script>
export default {
	components: {
		Upload: () => import('@/components/Upload/index.vue'),
		RsForm: () => import('@/components/RsForm/index.vue'),
	},
	data() {
		return {
			createdVisible: false,
			columns: [
				{
					prop: 'name',
					label: '科目名称',
					type: 'input',
					default: '',
				},
				{
					prop: 'trainingSubjectDictionaryItemId',
					label: '科目类别',
					type: 'select',
					default: null,
					options: [], //接口请求数据后再赋值
					optionsDefault: ['title', 'id'], //[label,value]
				},
				{
					label: '科目编码',
					type: 'input',
					prop: 'trainingSubjectCode',
					default: '',
				},
				{
					label: '创建人',
					type: 'input',
					prop: 'creatorName',
					default: '',
				},
				{
					prop: 'description',
					label: '科目介绍',
					type: 'textarea',
					default: '',
				},
				{
					label: '上传图片',
					type: 'uploadImg',
					prop: 'images',
					maxCount: 6,
					operator: 'zhangl',
					modulePath: 'TRAIN',
				},
			], //表单配置项
			rules: {
				name: [{ required: true, message: '请输入', trigger: 'blur' }],
				creatorName: [{ required: true, message: '请输入', trigger: 'blur' }],
				trainingSubjectCode: [{ required: true, message: '请输入', trigger: 'blur' }],
				trainingSubjectDictionaryItemId: [{ required: true, message: '请选择', trigger: 'change' }],
				description: [{ required: true, message: '请输入', trigger: 'blur' }],
				images: [{ required: true, message: '请上传图片', trigger: 'blur' }],
			},
			attachmentInfo: {},
			saveLoading: false,
			ruleForm: {},
		};
	},
	props: ['isShowDetail'],
	watch: {},
	mounted() {},
	methods: {
		init(row) {
			this.createdVisible = true;
			this.getSubjectList();
			this.getAttachment();
			if (row) {
				this.setRuleForm(row);
				return;
			}
			this.$nextTick(() => {
				this.$refs.rsForm.formData = {};
				this.$refs.rsForm.$refs['queryForm'].resetFields();
			});
		},
		// 获取科目类别
		getSubjectList() {
			this.$http
				.get(this.api.getDictionaryItems + '.json', { params: { dictionaryCode: 'SUBJECT' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.columns[1].options = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 获取附件组
		getAttachment() {
			this.$http
				.get(this.api.getAttachmentGroups, { params: { subjectTypeCode: 'SUBJECT' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.attachmentInfo = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 设置表单数据
		setRuleForm(data) {
			this.ruleForm = JSON.parse(JSON.stringify(data));
			this.$nextTick(() => {
				this.$refs.rsForm.formData = JSON.parse(JSON.stringify(data));
				this.$refs.rsForm.formData.trainingSubjectDictionaryItemId = data.trainingSubjectDictionaryItem.id;
				this.$refs.rsForm.formData.images = data.attachmentMap['PHOTO'].map((item) => {
					return {
						url: item.link,
						uid: item.id,
						title: item.title,
					};
				});
			});
		},
		// 保存
		onSave(data) {
			this.saveLoading = true;
			let par = {
				...data,
				attachments: data.images.map((item) => {
					return {
						link: item.url,
						subjectTypeCode: this.attachmentInfo.find((item) => item.code == 'PHOTO').subjectTypeCode,
						title: item.title,
						attachmentGroupId: this.attachmentInfo.find((item) => item.code == 'PHOTO').id,
						mimeType: item.title.split('.')[1],
					};
				}),
			};
			let primoseUrl = this.ruleForm.id
				? this.$http.put(this.api.getTrainingSubject + `/${this.ruleForm.id}.json`, par)
				: this.$http.post(this.api.getTrainingSubject + '.json', par);
			primoseUrl
				.then((res) => {
					if (res.data && res.data.success) {
						this.$message.success(this.ruleForm.id ? '修改成功' : '添加成功');
						this.$emit('getList');
						this.resetForm();
						this.saveLoading = false;
					}
				})
				.catch((e) => {
					this.saveLoading = false;
				});
		},
		resetForm() {
			this.ruleForm = {};
			this.$refs.rsForm.formData.images = [];
			this.createdVisible = false;
			this.$nextTick(() => {
				this.$refs.rsForm.formData = {};
				this.$refs.rsForm.$refs['queryForm'].resetFields();
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.postion {
	font-size: 18px;
	.icon-bianji1 {
		margin-right: 16px;
		color: #1db9b1;
	}
}
/deep/ .el-form {
	.el-form-item {
		.el-select,
		.el-input {
			width: 100%;
		}
	}
	> div {
		margin-bottom: 20px;
	}
	.el-form-item__label {
		line-height: 14px;
		height: 14px;
		margin-bottom: 8px;
		padding: 0;
	}
}
/deep/ .el-button {
	margin-left: 16px;
}
</style>